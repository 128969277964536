<template>
  <div class="compras">
<traslados></traslados>
  </div>
</template>

<script>
// @ is an alias to /src
import traslados from '@/components/traslados.vue'

export default {
  name: 'movimientos',
  components: {
    traslados
   
    
  }
}
</script>