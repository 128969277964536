<template>
  <div class="login">
  <login></login>
        
  </div>
</template>

<script>
// @ is an alias to /src
import login from '@/components/auth_users/Login_user.vue'



export default {
  name: 'ComprasdesdePOS',
  components: {
    
    login
  

    
  }
}
</script>