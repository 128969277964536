<template>
  <div class="compras">
 <compras></compras>
  </div>
</template>

<script>
// @ is an alias to /src
import compras from '@/components/compras.vue'

export default {
  name: 'movimientos',
  components: {
    compras
   
    
  }
}
</script>